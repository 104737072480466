import Popper from "popper.js";
window.jQuery = $;
window.$ = $;

require("bootstrap"); 

import './scss/app.scss';

$( document ).ready(function() {
    $('.nav-item').each(function() {
        $(this).click(function() {
            document.getElementById("navbarNavAltMarkup").classList.remove("show");
        });
    });
    $('.navbar-brand').each(function() {
        $(this).click(function() {
            document.getElementById("navbarNavAltMarkup").classList.remove("show");
        });
    });
});